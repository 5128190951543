import {
  Card,
  Col,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import InteractionItem from "./InteractionItem";

const data = [
  {
    type: "voice"
  },  
  {
    type: "chat"
  },
  {
    type: "email"
  },
];

const InteractionsList = ({ interactions }) => {

  return (
    <div className="live-preview">
      <ListGroup className="interactions-list">
        {data.map((item, index) => (
          <InteractionItem key={index} item={item} />
        ))}
      </ListGroup>
    </div>
  );
};

export default InteractionsList;
