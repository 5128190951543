import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  faPhone,
  faUserGroup,
  faUserPlus,
  faCircleUser,
  faComment
} from "@fortawesome/free-solid-svg-icons";

// import { faCircleUser, faComment } from "@fortawesome/free-regular-svg-icons";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isUserInfo, setIsUserInfo] = useState(false);
  const [isSoftphone, setIsSoftphone] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [isInteractions, setIsInteractions] = useState(false);
  const [isAssistance, setIsAssistance] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "UserInfo") {
      setIsUserInfo(false);
    }
    if (iscurrentState !== "Softphone") {
      setIsSoftphone(false);
    }
    if (iscurrentState !== "Chat") {
      setIsChat(false);
    }
    if (iscurrentState !== "Interactions") {
      setIsInteractions(false);
    }
    if (iscurrentState !== "Assistance") {
      setIsAssistance(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isUserInfo,
    isSoftphone,
    isChat,
    isInteractions,
    isAssistance
  ]);

  const menuItems = [
    {
      id: "userInfo",
      label: "User Info",
      icon: faCircleUser,
      link: "/user-info",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("UserInfo");
      },
    },
    {
      id: "softphone",
      label: "Softphone",
      icon: faPhone,
      link: "/softphone",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Softphone");
      },
    },
    {
      id: "chat",
      label: "Chat",
      icon: faComment,
      link: "/chat",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Chat");
      },
    },
    {
      id: "interactions",
      label: "Interactions",
      icon: faUserGroup,
      link: "/interactions",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Interactions");
      },
    },
    {
      id: "assistance",
      label: "Assistance",
      icon: faUserPlus,
      link: "/assistance",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Assistance");
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
