import React from "react";
import { Container, Card, Row, Col, CardBody } from "reactstrap";
import { Layout, Model } from "flexlayout-react";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
import InteractionsList from "../../sections/UserInfo/InteractionsList";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";

import "flexlayout-react/style/light.css";

var flexLayoutConfig = {
  global: {

  },
  borders: [],
  layout: {
    type: "row",
    weight: 100,
    children: [
      {
        type: "tabset",
        weight: 20,
        children: [
          {
            type: "tab",
            name: "Interactions",
            component: "interactions",
            enableFloat: true,
          },
        ],
      },
      {
        type: "tabset",
        weight: 80,
        children: [
          {
            type: "tab",
            name: "Main",
            component: "greeting",
            enableFloat: true,
          },
        ],
      },
    ],
  },
};

const model = Model.fromJson(flexLayoutConfig);

const UserInfo = () => {
  document.title = "Appoogee Contact Center | User Info";

  const factory = (node) => {
    var component = node.getComponent();

    if (component === "interactions") {
      return (
        <Card className="card-h-100 rounded">
          <PreviewCardHeader title="Interactions" />
          <CardBody>
            <InteractionsList />
          </CardBody>
        </Card>
      );
    }

    if (component === "greeting") {
      return <Card className="card-h-100 rounded"></Card>;
    }

    if (component === "button") {
      return <button>{node.getName()}</button>;
    }
  };

  // return <Layout model={model} factory={factory} />;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title="Calendar" pageTitle="Apps" /> */}
          <Row style={{height: "calc(100vh - 165px)", position: "relative"}}>
            {/* <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card className="card-h-100 rounded">
                    <PreviewCardHeader title="Interactions" />
                    <CardBody>
                      <InteractionsList />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={9}>
                  <Card className="card-h-100 rounded"></Card>
                </Col>
              </Row>
            </Col> */}
            <Layout model={model} factory={factory} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserInfo;
