import { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Input,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import "./Dialpad.css";

const charactersByNymber = {
  2: ["ABC"],
  3: ["DEF"],
  4: ["GHI"],
  5: ["JKL"],
  6: ["MNO"],
  7: ["PQRS"],
  8: ["TUV"],
  9: ["WXYZ"],
  0: ["+"],
};

const DialPad = () => {
  const [input, setInput] = useState("");

  // Handle digit/button clicks
  const handleClick = (value) => {
    setInput((prevInput) => prevInput + value);
  };

  // Handle clearing input
  const handleClear = () => {
    setInput("");
  };

  // Handle backspace
  const handleBackspace = () => {
    setInput((prevInput) => prevInput.slice(0, -1));
  };

  // Handle calling (submit, etc.)
  const handleCall = () => {
    alert(`Calling ${input}...`);
  };

  return (
    <UncontrolledDropdown id="bntGroupDrop">
      <DropdownToggle color="light">
        <i className="mdi mdi-dots-grid"></i>
      </DropdownToggle>
      <DropdownMenu>
        <div className="dialpad">
          <div className="dialpad-display">
            <Input type="text" value={input} readOnly />
          </div>
          <div className="dialpad-buttons">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((item) => (
              <Button
                color="light"
                key={item}
                style={{
                  justifyContent:
                    item === "*" || item === "#" ? "center" : "flex-start",
                }}
                onClick={() => handleClick(item.toString())}
              >
                <span>{item}</span>
                <span
                  className="aphabetical"
                  style={{
                    fontSize:
                      charactersByNymber[item]?.[0] === "+" ? "14px" : "10px",
                  }}
                >
                  {charactersByNymber[item]?.[0]}
                </span>
              </Button>
            ))}
          </div>
          <div className="dialpad-actions">
            <Button id="btnClear" color="light" onClick={handleClear}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </Button>
            <UncontrolledTooltip placement="top" target="btnClear">
              Clear
            </UncontrolledTooltip>
            <Button
              id="btnCall"
              style={{ width: "100px" }}
              color="success"
              onClick={handleCall}
            >
              <FontAwesomeIcon icon={faPhone} />
            </Button>
            <UncontrolledTooltip placement="top" target="btnCall">
              Call
            </UncontrolledTooltip>
            <Button id="btnBackspace" color="light" onClick={handleBackspace}>
              <FontAwesomeIcon icon={faDeleteLeft} />
            </Button>
            <UncontrolledTooltip placement="top" target="btnBackspace">
              Backspace
            </UncontrolledTooltip>
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default DialPad;
