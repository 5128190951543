import React from "react";
import { Container } from "reactstrap";


const Interactions = () => {
  document.title = "Appoogee Contact Center | Interactions";


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Interactions;
