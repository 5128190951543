import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import UserInfo from "../pages/UserInfo";
import Softphone from "../pages/Softphone";
import Chat from "../pages/Chat";
import Interactions from "../pages/Interactions";
import Assistance from "../pages/Assistance";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";


// User Profile
import UserProfile from "../pages/Authentication/user-profile";

const authProtectedRoutes = [
  { path: "/user-info", component: <UserInfo /> },
  { path: "/softphone", component: <Softphone /> },
  { path: "/chat", component: <Chat /> },
  { path: "/interactions", component: <Interactions /> },
  { path: "/assistance", component: <Assistance /> },
  { path: "/index", component: <UserInfo /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/user-info" />,
  },
  { path: "*", component: <Navigate to="/user-info" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

];

export { authProtectedRoutes, publicRoutes };