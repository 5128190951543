import { ListGroupItem } from "reactstrap";
import VoiceItem from "./VoiceItem";

const InteractionItem = ({ item }) => {

  const renderItem = (item) => {
    switch (item.type) {
      case "voice":
        return <VoiceItem item={item} />;
      case "chat":
      case "email":
      default:
        return <ListGroupItem>{item.type}</ListGroupItem>;
    }
  }

  return (
    renderItem(item)
  );
};

export default InteractionItem;
