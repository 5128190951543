import { useState } from "react";
import {
  ListGroupItem,
  Card,
  ButtonGroup,
  Button,
  Tooltip,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  faPhone,
  faPause,
  faPhoneSlash,
  faMicrophone,
  faMicrophoneSlash,
  faPlay,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialPad from "./Dialpad";

const VoiceItem = ({ item }) => {
  const [isActiveCall, setIsActiveCall] = useState(false);
  const [isMute, setIsMuted] = useState(false);
  const [isHold, setIsHold] = useState(true);

  const handleAnswer = () => {
    setIsActiveCall(true);
  };

  const handleEndCall = () => {
    setIsActiveCall(false);
    setIsHold(true);
    setIsMuted(false);
  };

  const handleMute = () => {
    setIsMuted(!isMute);
  };

  const handleHold = () => {
    setIsHold(false);
  }

  const handleResume = () => {
    setIsHold(true);
  }
  const opanDialpad = () => {
    console.log("Dialpad");
  };

  return (
    <ListGroupItem>
      <Card>
        <ButtonGroup className="interaction-actions">
          {!isActiveCall && (
            <Button
              color="light"
              id={`btnAnswer_${item.type}`}
              onClick={handleAnswer}
            >
              <FontAwesomeIcon color="var(--vz-success)" icon={faPhone} />
              <UncontrolledTooltip
                placement="top"
                target={`btnAnswer_${item.type}`}
              >
                Answer
              </UncontrolledTooltip>
            </Button>
          )}
          {isActiveCall &&
            (isMute ? (
              <Button
                color="light"
                id={`btnAnswer_${item.type}`}
                onClick={handleMute}
              >
                <FontAwesomeIcon
                  color="var(--vz-danger)"
                  icon={faMicrophoneSlash}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`btnAnswer_${item.type}`}
                >
                  Unmute
                </UncontrolledTooltip>
              </Button>
            ) : (
              <Button
                color="light"
                id={`btnAnswer_${item.type}`}
                onClick={handleMute}
              >
                <FontAwesomeIcon icon={faMicrophone} />
                <UncontrolledTooltip
                  placement="top"
                  target={`btnAnswer_${item.type}`}
                >
                  Mute
                </UncontrolledTooltip>
              </Button>
            ))}
          {isHold ? (
            <Button
              color="light"
              id={`btnHold_${item.type}`}
              onClick={handleHold}
              disabled={!isActiveCall}
            >
              <FontAwesomeIcon icon={faPause} />
              <UncontrolledTooltip
                placement="top"
                target={`btnHold_${item.type}`}
              >
                Hold
              </UncontrolledTooltip>
            </Button>
          ) : (
            <Button
              color="light"
              id={`btnHold_${item.type}`}
              onClick={handleResume}
            >
              <FontAwesomeIcon icon={faPlay} />
              <UncontrolledTooltip
                placement="top"
                target={`btnHold_${item.type}`}
              >
                Resume
              </UncontrolledTooltip>
            </Button>
          )}
          <DialPad />
          <UncontrolledDropdown id="bntGroupDrop">
            <DropdownToggle color="light" disabled={!isActiveCall}>
              <FontAwesomeIcon icon={faCaretDown} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>Transfer</DropdownItem>
              <DropdownItem>Conference</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Button
            color="light"
            id={`btnEnd_${item.type}`}
            onClick={handleEndCall}
          >
            <FontAwesomeIcon color="var(--vz-danger)" icon={faPhoneSlash} />
            <UncontrolledTooltip placement="top" target={`btnEnd_${item.type}`}>
              End
            </UncontrolledTooltip>
          </Button>
        </ButtonGroup>
        
      </Card>
    </ListGroupItem>
  );
};

export default VoiceItem;
